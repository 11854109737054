<template>
    <v-container>
        <div class="d-flex align-center flex-wrap flex-md-nowrap" style="gap: 16px">
            <v-select
                v-model="anioActivo"
                :items="aniosFiscales"
                :loading="aniosFiscalesCargando || cargaDocumento.isLoading"
                item-value="id"
                item-text="anio"
                hide-details
                label="Año"
                outlined
                clearable
                class="flex-grow-1 flex-shrink-1"
                return-object
                @change="cargarDataAnioFiscal"
            />

            <v-btn 
                color="primary"
                :disabled="!anioActivo" 
                @click="downloadPac(true)"
                x-large
                class="flex-grow-1 flex-shrink-1"
            >
                Reporte público
            </v-btn>

            <v-btn 
                color="primary"
                :disabled="!anioActivo" 
                @click="downloadPac(false)"
                x-large
                class="flex-grow-1 flex-shrink-1"
            >
                Reporte normal
            </v-btn>
        </div>
        
        <v-card v-if="anioActivo" class="px-4 py-4 my-4 mx-auto">
            <template v-if="documentoActivo">
              <v-card-title>Documento PAC año {{ documentoActivo.AnioFiscal.anio  }}</v-card-title>
              <div  class="d-flex align-center" style="gap: 16px;">
                <v-btn 
                    v-if="!documentoActivo.aprobado" 
                    color="primary" 
                    @click.stop="confirmarAprobacion"
                >
                    Aprobar
                </v-btn>
                <v-chip
                    v-else
                    label
                    class="ma-2"
                    color="green"
                    text-color="white"
                >
                    Aprobado
                </v-chip>
                <v-btn color="primary" @click.stop="visualizarAdjunto">Ver adjunto</v-btn>
              </div>
            </template>
            <EmptyComponent
                v-else
                :empty="!documentoActivo && !cargaDocumento.isLoading"
                title="No hay documento cargado"
                text="No se ha cargado ningún documento para el año seleccionado"
                class="mt-4"
            />

            <DataTableComponent
                dense
                noGutters
                :headers="headers"
                :items="procesos.data"
                :show_loading="procesos.isLoading"
                :total_registros="total"
                v-models:pagina="paginaActual"
                v-models:select="registrosPorPagina"
                @paginar="manejarPaginacionRegistros"
                class="mt-4"
            >
                <template #[`item.fecha_fin`]="{ item }">
                    <span>{{ obtenerNombreMes(item.fecha_fin) }}</span>
                </template>
                <template #[`item.monto_planificado`]="{ item }">
                    <span>
                    {{
                        Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                        }).format(item.monto_planificado)
                    }}
                    </span>
                </template>
                <template v-slot:[`item.estado_proceso`]="{ item }">
                    <v-chip
                    v-if="item?.SeguimientoProceso"
                    style="display: block"
                    label
                    class="bgWhite--text"
                    :color="colorProceso(item?.SeguimientoProceso?.color)"
                    >
                    {{ item?.SeguimientoProceso?.nombre }}
                    </v-chip>
                    <v-chip
                    v-else
                    style="display: block"
                    label
                    class="bgWhite--text"
                    color="error"
                    >
                    Proceso eliminado
                    </v-chip>
                </template>
            </DataTableComponent>
        </v-card>
        <EmptyComponent
            v-else
            :empty="!anioActivo"
            title="Selecciona el año fiscal"
            text="Seleccione el año fiscal para cargar el documento"
            class="mt-4"
        >
            <template v-slot:icon="{ className }">
                <v-icon :class="className">mdi-list-box</v-icon>
            </template>
        </EmptyComponent>

        <ConfirmationModalComponent 
            :is-open="modalConfirmacionAprobacionAbierta"
            description="Desea aprobar el documento"
            :is-loading="aprobacion.isLoading"
            @confirm="aprobarDocumento"
            @cancel="modalConfirmacionAprobacionAbierta = false"
        />
        <PdfModal
            :isOpen="modalVisualizacionAdjuntoAbierta" 
            :source-loadable="visualizacionAdjunto" 
            @on-visibility-change="manejarVisibilidadModalVisualizacionAdjunto"
            filename="adjunto-anios-fiscal-pac"
        />
    </v-container>
</template>
<script>
import { 
    createLoadable, 
    createPageable, 
    toggleLoadable, 
    togglePageable,
    setLoadableResponse,
    setPageableResponse,
    isResponseSuccesful,
} from'@/utils/loadable';
import DataTableComponent from '@/components/DataTableComponent.vue';
import PdfModal from '@/components/PdfModal.vue';
import { ConfirmationModalComponent, EmptyComponent } from '@/components/utils';
import {mapState, mapMutations} from "vuex";
import moment from 'moment';

export default {
    name: 'aprobacionPacAnioFiscal',
    components: { ConfirmationModalComponent, PdfModal, DataTableComponent, EmptyComponent },
    data: () =>({
        aniosFiscales: [],
        aniosFiscalesCargando: false,
        // Utils
        anioActivo: null,
        // Documento por anio
        cargaDocumento: createLoadable([]),
        // Aprobacion
        modalConfirmacionAprobacionAbierta: false,
        aprobacion: createLoadable(null),
        // Ver adjunto
        modalVisualizacionAdjuntoAbierta: false,
        visualizacionAdjunto: createLoadable(null),
        // procesos
        procesos: createPageable([], 10),
        // tabla
        headers: [
            { align: 'center', sortable: false, text: 'Código', value: 'correlativo' },
            { align: 'center', sortable: false, text: 'Proceso', value: 'nombre' },
            { align: 'center', sortable: false, text: 'Modalidad de compra', value: 'FormaContratacion.nombre' },
            { align: 'center', sortable: false, text: 'Mes programado', value: 'fecha_fin' },
            { align: 'center', sortable: false, text: 'Monto planificado', value: 'monto_planificado' },
            { align: 'center', sortable: false, text: 'Estado', value: 'estado_proceso' },
        ],
        paginaActual: 1,
        registrosPorPagina: 10,
    }),
    computed: {
        documentoActivo() {
            return this.cargaDocumento.data[0];
        },
        total() {
            return this.procesos.pagination.total_rows;
        },
        ...mapState([
        "userInfo",
        "selectedUnidad",
    ]),
    },
    methods: {
        async cargarAniosFiscales() {
            this.aniosFiscalesCargando = true;
            try {
                const { data } = await this.services.DocumentosAniosFiscalServices.cargarAniosFiscales();
                this.aniosFiscales = data;
            } catch (error) { }
            finally {
                this.aniosFiscalesCargando = false;
            }
        },
        async downloadPac(isPublic) {
            let response = null;
            if ( isPublic) {
                    response = await this.services.DocumentosAniosFiscalServices.descargarReportePacPublico(
                    this.userInfo.user.id_institucion,
                    { anio_calendario: this.anioActivo.anio }

                );
            }
            else {
                response = await this.services.DocumentosAniosFiscalServices.descargarReportePacPrivado(
                    this.userInfo.user.id_institucion,
                    { anio_calendario: this.anioActivo.anio }
                );
            }
            
            if (!response) {
                this.temporalAlert({
                    show: true,
                    message: "Ocurrió un error al descargar el documento",
                    type: "error",
                });
                return;
            }

            const blob = new Blob([response.data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });

            const file = new File([blob], this.selectedUnidad.instituciones.nombre, {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });

            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(file);
            link.download = `ReportePAC-${this.anioActivo?.anio}.xlsx`;
            link.click();
        },

        cargarDataAnioFiscal() {
            this.paginaActual = 1;
            if (this.anioActivo) {
                this.cargarPacPorAnio();
                this.cargarProcesosPorAnio();
            } else {
                this.procesos = createPageable([], 10);
                this.cargaDocumento = createLoadable([]);
            }
        },
        async cargarPacPorAnio() {
            toggleLoadable(this.cargaDocumento);
            const filtros = {
                id_anio_fiscal: this.anioActivo.id,
                pagination: false,
            }
            const { data } = await this.services.DocumentosAniosFiscalServices.cargarDocumentos(filtros);
            setLoadableResponse(this.cargaDocumento, data);
        },
        confirmarAprobacion() {
            this.modalConfirmacionAprobacionAbierta = true;
        },
        async aprobarDocumento() {
            toggleLoadable(this.aprobacion);
            const id = this.documentoActivo.id;
            const { data } = await this.services.DocumentosAniosFiscalServices.aprobarDocumento(id);
            setLoadableResponse(this.aprobacion, data, { skipOnSuccess: true, showAlertOnSuccess: true });
            if (isResponseSuccesful(data)) {
                this.cargarDataAnioFiscal();
            }
            this.modalConfirmacionAprobacionAbierta = false;
        },
        async visualizarAdjunto() {
            this.modalVisualizacionAdjuntoAbierta = true;
            const idAdjunto = this.documentoActivo.id;
            toggleLoadable(this.visualizacionAdjunto);
            const { data } = await this.services.DocumentosAniosFiscalServices.visualizarAdjunto(idAdjunto);
            setLoadableResponse(this.visualizacionAdjunto, data, { isFile: true });
        },
        manejarVisibilidadModalVisualizacionAdjunto(visible) {
            this.modalVisualizacionAdjuntoAbierta = visible;
        },
        // cargar procesos PAC
        async cargarProcesosPorAnio() {
            togglePageable(this.procesos);
            const filtros = {
                pagination: true,
                per_page: this.registrosPorPagina,
                page: this.paginaActual,
            }

            const { data, headers } = await this.services.DocumentosAniosFiscalServices.cargarProcesosPorAnio(
                this.anioActivo.anio,
                filtros,
            );
            setPageableResponse(this.procesos, data, headers);
        },
        manejarPaginacionRegistros(paginacion) {
            const { pagina, cantidad_por_pagina } = paginacion;
            this.paginaActual = pagina;
            this.registrosPorPagina = cantidad_por_pagina;
            this.cargarProcesosPorAnio();
        },
        obtenerNombreMes(fecha) {
            return moment(fecha).locale('es').format('MMMM');
        },
        colorProceso(id_estado_proceso) {
            switch (id_estado_proceso) {
                case 1:
                return "#8EBCE1";
                case 2:
                return "#4578D5";
                default:
                return "secondary";
            }
        },
    },
    created() {
        this.cargarAniosFiscales();
    },
}
</script>
<style lang="scss" scoped>
.bg-dark {
    background: #343a40;
}

.color-dark {
    color: #343a40;
}
</style>